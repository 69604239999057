export default {
  БУЛЬВАР: 'Бульвар',
  ВАЛ: 'Вал',
  'ВОЕННЫЙ ГОРОДОК': 'Военный городок',
  'ВОИНСКАЯ ЧАСТЬ': 'Воинская часть',
  КВАРТАЛ: 'Квартал',
  КОЛЬЦО: 'Кольцо',
  МИКРОРАЙОН: 'Микрорайон',
  НАБЕРЕЖНАЯ: 'Набережная',
  ПАРК: 'Парк',
  ПЕРЕУЛОК: 'Переулок',
  ПЛОЩАДЬ: 'Площадь',
  ПОСЕЛОК: 'Поселок',
  ПРОЕЗД: 'Проезд',
  ПРОСПЕКТ: 'Проспект',
  СТАНЦИЯ: 'Станция',
  ТЕРРИТОРИЯ: 'Территория',
  ТРАКТ: 'Тракт',
  ТУПИК: 'Тупик',
  УЛИЦА: 'Улица',
  УРОЧИЩЕ: 'Урочище',
  ШОССЕ: 'Шоссе',
  ИНОЙ: 'Иной',
  ВЪЕЗД: 'Въезд',
};
