import incomeTypes from '../types/income';
import incomeSubStepTypes from '../types/income-substeps';
import livingConditionsTypes from '../types/living-conditions';
import localityType from '../types/locality-type';
import maritalStatusTypes from '../types/marital-status';
import streetType from '../types/street-type';
import { stripPhoneFormatting } from './format';
import { datePattern, phonePattern } from './patterns';

export const MAX_INPUT_LENGTH = 1024;
export const MAX_NUMERIC_VALUE = Number.MAX_SAFE_INTEGER;

export const validateInputs = (data, inputs, requiredSkipMap) => {
  let result = { status: true, failedInputs: {} };
  for (let fieldName of Object.keys(inputs)) {
    if (inputs[fieldName].required && !requiredSkipMap?.[fieldName]) {
      if (!data[fieldName]?.toString()?.trim()) {
        result.status = false;
        result.failedInputs[fieldName] = {
          message: 'Обязательное к заполнению поле',
          name: inputs[fieldName]?.label,
        };
      }

      if (
        inputs[fieldName].type === 'int' ||
        inputs[fieldName].type === 'float'
      ) {
        if (!parseInt(data[fieldName])) {
          result.status = false;
          result.failedInputs[fieldName] = {
            message: 'Обязательное к заполнению поле не может иметь значение 0',
            name: inputs[fieldName]?.label,
          };
        }
      }
    }

    if (inputs[fieldName].type === 'phone' && data[fieldName]) {
      const cleanValue = `${stripPhoneFormatting(data[fieldName])}`;
      if (!cleanValue?.match(phonePattern)) {
        result.status = false;
        result.failedInputs[fieldName] = {
          message: 'Телефон должен соответствовать формату +375...',
          name: inputs[fieldName]?.label,
        };
      }
    }

    if (inputs[fieldName].type === 'date' && data[fieldName]) {
      if (!data[fieldName]?.match(datePattern)) {
        result.status = false;
        result.failedInputs[fieldName] = {
          message:
            'Дата должна быть корректной и соответсвовать формату дд.мм.гггг',
          name: inputs[fieldName]?.label,
        };
      }
    }

    const maxValue = inputs[fieldName].maxValue || MAX_NUMERIC_VALUE;
    if (inputs[fieldName].type === 'float' && data[fieldName]) {
      if (parseFloat(data[fieldName]).toFixed(2) > maxValue) {
        result.status = false;
        result.failedInputs[fieldName] = {
          message: `Значение не должно превышать ${maxValue}`,
          name: inputs[fieldName]?.label,
        };
      }
    }

    const maxLength = inputs[fieldName].maxLength || MAX_INPUT_LENGTH;
    if (data[fieldName]?.length > maxLength) {
      result.status = false;
      result.failedInputs[fieldName] = {
        message: `Длина значения не должна превышать ${inputs[fieldName].maxLength || MAX_INPUT_LENGTH} симв.`,
        name: inputs[fieldName]?.label,
      };
    }

    const minLength = inputs[fieldName].minLength || null;
    if (minLength && data[fieldName]?.length < minLength) {
      result.status = false;
      result.failedInputs[fieldName] = {
        message: `Длина значения не должна быть меньше ${inputs[fieldName].minLength} симв.`,
        name: inputs[fieldName]?.label,
      };
    }
  }

  return result;
};

export const personalDataStepInputs = {
  // familyNamePrevious: {
  //   label: 'Предыдущая фамилия, если менялась',
  //   maxLength: 64,
  // },
};

const addressFieldNames = [
  'addressRegion',
  'addressDistrict',
  'addressUnitaryArea',
  'addressLocalityType',
  'addressLocality',
  'addressStreetType',
  'addressStreet',
  'addressHouse',
  'addressBuilding',
  'addressApartment',
  'addressPostalCode',
];

const addressFields = addressFieldNames.reduce((acc, field) => {
  const trimmedField = field.slice(1);
  return {
    ...acc,
    [field]: {
      plain: field,
      living: `livingA${trimmedField}`,
      reg: `regA${trimmedField}`,
    },
  };
}, {});

export const addressStepInputs = {
  [addressFields.addressRegion.living]: { label: 'Область', maxLength: 64 },
  [addressFields.addressDistrict.living]: { label: 'Район', maxLength: 64 },
  [addressFields.addressUnitaryArea.living]: {
    label: 'Сельсовет',
    maxLength: 64,
  },
  [addressFields.addressLocalityType.living]: {
    label: 'Тип населенного пункта',
    type: 'select',
    optionTypes: localityType,
  },
  [addressFields.addressLocality.living]: {
    label: 'Населенный пункт',
    maxLength: 64,
  },
  [addressFields.addressStreetType.living]: {
    label: 'Тип улицы',
    type: 'select',
    optionTypes: streetType,
  },
  [addressFields.addressStreet.living]: {
    label: 'Улица',
    maxLength: 64,
  },
  [addressFields.addressHouse.living]: {
    label: 'Дом',
    maxLength: 16,
  },
  [addressFields.addressBuilding.living]: {
    label: 'Корпус',
    maxLength: 16,
  },
  [addressFields.addressApartment.living]: { label: 'Квартира', maxLength: 16 },
  [addressFields.addressPostalCode.living]: {
    label: 'Индекс',
    type: 'int',
    maxLength: 6,
  },
};

export const incomeStepInputs = {
  [incomeSubStepTypes.FAMILY]: {
    maritalStatus: {
      label: 'Семейное положение',
      type: 'select',
      optionTypes: maritalStatusTypes,
      required: true,
    },
    livingConditions: {
      label: 'Условия проживания',
      type: 'select',
      optionTypes: livingConditionsTypes,
      required: true,
    },
  },
  [incomeSubStepTypes.JOB]: {
    incomeMainType: {
      label: 'Основной доход',
      type: 'select',
      optionTypes: incomeTypes,
      required: true,
    },
    incomeMainAmount: {
      label: 'Размер, BYN',
      required: true,
      type: 'float',
      maxValue: 9999999999.99,
      maxLength: 13,
    },
    jobPlace: {
      label: 'Работодатель',
      required: true,
      maxLength: 256,
    },
  },
  [incomeSubStepTypes.CONTACTS]: {
    additionalPhoneNumber: {
      label: 'Дополнительный телефон',
      type: 'phone',
    },
  },
};

export const checkRegAndLivingAddressEquality = (
  interbankData,
  userInputData,
) => {
  return checkAddressEquality(
    { obj: interbankData, type: 'reg' },
    { obj: userInputData, type: 'living' },
  );
};

export const generateLivingAddrFromOriginal = (interbankData, type) => {
  const newLivingAddr = {};

  for (let fieldName of addressFieldNames) {
    newLivingAddr[addressFields[fieldName].living] =
      interbankData[addressFields[fieldName][type]] || '';
  }

  return newLivingAddr;
};

export const checkAddressEquality = (firstAddr, secondAddr) => {
  let areEqual = true;

  for (let fieldName of addressFieldNames) {
    const firstAddrVal = firstAddr.obj[addressFields[fieldName][firstAddr.type]]
      ?.trim()
      ?.toLowerCase();
    const secondAddrVal = secondAddr.obj[
      addressFields[fieldName][secondAddr.type]
    ]
      ?.trim()
      .toLowerCase();
    if (
      !(firstAddrVal === secondAddrVal || (!firstAddrVal && !secondAddrVal))
    ) {
      areEqual = false;
      break;
    }
  }

  return areEqual;
};

const inputModificationModes = {
  PICK: 'pick',
  DROP: 'drop',
};

const modifyInputs = (inputs, inputNamesToPick, mode) => {
  const inputsCopy = { ...inputs };

  for (const inputName in inputsCopy) {
    const isListed = inputNamesToPick.includes(inputName);
    const shouldDrop =
      (mode === inputModificationModes.DROP && isListed) ||
      (mode === inputModificationModes.PICK && !isListed);
    if (shouldDrop) {
      delete inputsCopy[inputName];
    }
  }

  return inputsCopy;
};

export const dropInputs = (inputs, inputNamesToPick) => {
  return modifyInputs(inputs, inputNamesToPick, inputModificationModes.DROP);
};

export const pickInputs = (inputs, inputNamesToPick) => {
  return modifyInputs(inputs, inputNamesToPick, inputModificationModes.PICK);
};
