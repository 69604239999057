import { useEffect, useState } from 'react';
import { InternalStepStateContext } from '../../context/internal-step-state-context';
import AddressStep from './address/AddressStep';
import DocumentsStep from './documents/DocumentsStep';
import IncomeStep from './income/IncomeStep';
import PersonalDataReview from './personal-data/PersonalDataReview';
import StepStatus from './StepStatus';

const StepsMainContent = ({ scrollTop }) => {
  const formSteps = ['Личные данные', 'Адрес', 'Доход', 'Документы'];
  const [internalStepState, setInternalStepState] = useState({
    regAddressReviewed: false,
    isEqualToRegAddress: true,
    documentsReviewed: false,
    showAcceptDocsError: false,
    requiredDocs: [],
    confirmationCode: '',
    lastCodeSentAt: null,
  });

  const [currentFormStepIndex, setCurrentFormStepIndex] = useState(0);

  useEffect(() => {
    scrollTop();
  }, [currentFormStepIndex, internalStepState.regAddressReviewed]);

  const handleFormStepChange = (delta) => {
    setCurrentFormStepIndex((prev) => {
      const newStep = prev + delta;
      return newStep < 0
        ? 0
        : newStep >= formSteps.length
          ? formSteps.length - 1
          : newStep;
    });
  };

  return (
    <InternalStepStateContext.Provider
      value={{ internalStepState, setInternalStepState }}
    >
      {currentFormStepIndex < 4 ? (
        <StepStatus steps={formSteps} currentStepIndex={currentFormStepIndex} />
      ) : (
        ''
      )}

      {currentFormStepIndex === 0 ? (
        <PersonalDataReview onStepComplete={() => handleFormStepChange(+1)} />
      ) : (
        ''
      )}

      {currentFormStepIndex === 1 ? (
        <AddressStep
          onStepComplete={() => handleFormStepChange(+1)}
          onStepBack={() => handleFormStepChange(-1)}
        />
      ) : (
        ''
      )}
      {currentFormStepIndex === 2 ? (
        <IncomeStep
          onStepComplete={() => handleFormStepChange(+1)}
          onStepBack={() => handleFormStepChange(-1)}
        />
      ) : (
        ''
      )}
      {currentFormStepIndex === 3 ? (
        <DocumentsStep
          onStepComplete={() => handleFormStepChange(+1)}
          onStepBack={() => handleFormStepChange(-1)}
        />
      ) : (
        ''
      )}
    </InternalStepStateContext.Provider>
  );
};

export default StepsMainContent;
