import { useRef } from 'react';
import { ReactComponent as ChevronIcon } from '../assets/images/chevron-icon.svg';
import styles from './Select.module.css';
const Select = ({
  optionTypes,
  label,
  required,
  value,
  name,
  onChangeCallback,
  className,
  style,
  error,
  isMultiSelect,
  resetError,
  disabled,
}) => {
  const selectRef = useRef();
  const handleChange = (selectedOption) => {
    if (disabled) return;

    if (onChangeCallback && name) {
      if (isMultiSelect) {
        let newValue;

        if (value?.includes(selectedOption)) {
          newValue = value.filter((v) => v !== selectedOption);
          if (!newValue.length) {
            newValue = null;
          }
        } else {
          newValue = value ? [...value, selectedOption] : [selectedOption];
        }

        onChangeCallback({ name: name, value: newValue });
      } else {
        onChangeCallback({ name: name, value: selectedOption });
        selectRef?.current?.blur();
      }
    }

    if (resetError) {
      resetError();
    }
  };

  const handleChildClick = (e) => {
    if (disabled) return;

    if (document.activeElement === selectRef.current) {
      selectRef.current.blur();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div
      ref={selectRef}
      tabIndex={0}
      className={`${styles.select} ${className} ${disabled && styles.isDisabled} ${error ? styles.error : ''}`}
      style={style}
    >
      <span className={styles.label}>
        <span className={styles.labelText}>{label}</span>{' '}
        {required ? <span className={styles.requiredIndicator}>*</span> : ''}
      </span>
      <div className={styles.valueContainer} onMouseDown={handleChildClick}>
        {value && (
          <span>
            {isMultiSelect
              ? value.map((v) => optionTypes[v]).join(', ')
              : Array.isArray(optionTypes)
                ? value
                : optionTypes[value]}
          </span>
        )}
        {!value && (
          <span className={styles.emptyValue}>
            {isMultiSelect
              ? 'Выберите несколько вариантов'
              : 'Выберите вариант'}
          </span>
        )}
      </div>
      <div className={styles.iconWrapper} onMouseDown={handleChildClick}>
        <ChevronIcon />
      </div>
      <div className={styles.optionList}>
        {Array.isArray(optionTypes)
          ? optionTypes.map((option) => {
              const isSelected =
                (!isMultiSelect && option === value) ||
                (isMultiSelect && value?.includes(option));
              return (
                <div
                  className={`${styles.option} ${isSelected ? styles.selected : ''}`}
                  key={option}
                  value={option}
                  onClick={() => handleChange(option)}
                >
                  <span>{isMultiSelect && isSelected && '✓ '}</span>
                  {option}
                </div>
              );
            })
          : Object.keys(optionTypes).map((option) => {
              const isSelected =
                (!isMultiSelect && option === value) ||
                (isMultiSelect && value?.includes(option));
              return (
                <div
                  className={`${styles.option} ${isSelected ? styles.selected : ''}`}
                  key={option}
                  value={option}
                  onClick={() => handleChange(option)}
                >
                  <span>{isMultiSelect && isSelected && '✓ '}</span>
                  {optionTypes[option]}
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Select;
