export default {
  ГОРОД: 'Город',
  'НАСЕЛЕННЫЙ ПУНКТ': 'Населенный пункт',
  АУЛ: 'Аул',
  'ПОСЕЛОК ГОРОДСКОГО ТИПА': 'Поселок городского типа',
  'РАБОЧИЙ ПОСЕЛОК': 'Рабочий поселок',
  'КУРОРТНЫЙ ПОСЕЛОК': 'Курортный поселок',
  'ПОСЕЛОК СЕЛЬСКОГО ТИПА': 'Поселок сельского типа',
  'ЖЕЛЕЗНОДОРОЖНАЯ СТАНЦИЯ': 'Железнодорожная станция',
  ДЕРЕВНЯ: 'Деревня',
  ХУТОР: 'Хутор',
  СЕЛО: 'Село',
  СТАНЦИЯ: 'Станция',
  МЕСТЕЧКО: 'Местечко',
  РАЗЪЕЗД: 'Разъезд',
  СТАНИЦА: 'Станица',
  АГРОГОРОДОК: 'Агрогородок',
  'СЕЛЬСКИЙ НАСЕЛЕННЫЙ ПУНКТ': 'Сельский населенный пункт',
  'ГОРОДСКОЙ ПОСЕЛОК': 'Городской поселок',
  ИНОЙ: 'Иной',
};
