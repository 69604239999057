import { useContext, useEffect } from 'react';
import { ClientDataContext } from '../../../context/client-data';
import { InternalStepStateContext } from '../../../context/internal-step-state-context';
import { UserInputDataContext } from '../../../context/user-input-data';
import {
  addressStepInputs,
  generateLivingAddrFromOriginal,
} from '../../../utils/inputs';
import FormInputSet from './../../FormInputSet';
import RadioInput from './../../RadioInput';

const AddressEditingForm = ({ failedInputs }) => {
  const { clientData } = useContext(ClientDataContext);
  const { setUserInputData } = useContext(UserInputDataContext);
  const { internalStepState, setInternalStepState } = useContext(
    InternalStepStateContext,
  );

  const isEqualToRegAddress = internalStepState.isEqualToRegAddress;

  const setIsEqualToRegAddress = (value) => {
    setInternalStepState({ ...internalStepState, isEqualToRegAddress: value });
  };

  useEffect(() => {
    handleAddressEqualityChange(isEqualToRegAddress);
  }, []);

  const handleAddressEqualityChange = (value) => {
    setIsEqualToRegAddress(value);

    if (value) {
      setUserInputData((prev) => ({
        ...prev,
        isLivingAddressMatchesReg: value,
        ...generateLivingAddrFromOriginal(clientData?.interbankData, 'reg'),
      }));
    } else {
      setUserInputData((prev) => ({
        ...prev,
        isLivingAddressMatchesReg: value,
        ...generateLivingAddrFromOriginal(clientData?.interbankData, 'living'),
      }));
    }
  };

  const handleAddressEqualityChangeFromRadio = ({ value }) => {
    if (value === isEqualToRegAddress) return;

    handleAddressEqualityChange(value);
  };

  return (
    <FormInputSet
      inputGroups={[
        {
          inputs: addressStepInputs,
          sameAsInterbankData: isEqualToRegAddress,
          groupChildren: (
            <RadioInput
              value={isEqualToRegAddress}
              label={'Адрес проживания совпадает с адресом регистрации?'}
              name={'equalitySwitch'}
              onChangeCallback={handleAddressEqualityChangeFromRadio}
            />
          ),
        },
      ]}
      failedInputs={failedInputs}
    />
  );
};

export default AddressEditingForm;
