import { useContext } from 'react';
import { ClientDataContext } from '../../../context/client-data';
import incomeSubstepTypes from '../../../types/income-substeps';
import { formatPhone } from '../../../utils/format';
import { incomeStepInputs, pickInputs } from '../../../utils/inputs';
import FormInputSet from '../../FormInputSet';
import Input from '../../Input';

const IncomeEditingForm = ({ failedInputs, currentSubStep }) => {
  const { clientData } = useContext(ClientDataContext);

  const mainPhoneNumber =
    clientData?.interbankData?.phoneSmsVerification ||
    clientData?.interbankData?.sessionInfoAuthPhone;

  return (
    <div>
      {currentSubStep === incomeSubstepTypes.FAMILY && (
        <FormInputSet
          inputGroups={[
            {
              inputs: pickInputs(incomeStepInputs[currentSubStep], [
                'maritalStatus',
                'livingConditions',
              ]),
            },
          ]}
          failedInputs={failedInputs}
        />
      )}

      {currentSubStep === incomeSubstepTypes.JOB && (
        <FormInputSet
          inputGroups={[
            {
              inputs: pickInputs(incomeStepInputs[currentSubStep], [
                'incomeMainType',
                'incomeMainAmount',
              ]),
            },
            {
              inputs: pickInputs(incomeStepInputs[currentSubStep], [
                'jobPlace',
              ]),
            },
          ]}
          failedInputs={failedInputs}
        />
      )}
      {currentSubStep === incomeSubstepTypes.CONTACTS && (
        <FormInputSet
          inputGroups={[
            {
              inputs: [],
              groupChildren: (
                <Input
                  value={formatPhone(mainPhoneNumber)}
                  label={'Мобильный телефон'}
                  required
                  disabled
                />
              ),
            },
            {
              inputs: pickInputs(incomeStepInputs[currentSubStep], [
                'additionalPhoneNumber',
              ]),
            },
          ]}
          failedInputs={failedInputs}
        />
      )}
    </div>
  );
};

export default IncomeEditingForm;
