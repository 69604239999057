import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Api from '../../api/Api';
import { ApiContext } from '../../context/api';
import { ClientDataContext } from '../../context/client-data';
import { UserInputDataContext } from '../../context/user-input-data';
import {
  populateUserInputData,
  prepareUserInputData,
} from '../../utils/server-data-helpers';
import ErrorPopup from '../ErrorPopup';
import FatalError from '../FatalError';
import LoadingIndicator from '../LoadingIndicator';
import StepsMainContent from '../steps/StepsMainContent';
import SuccessBlock from '../SuccessBlock';
import UntouchedRequestBlock from '../UntouchedRequestBlock';

const RequestPage = ({ scrollTop, scrollBottom }) => {
  const [api, setApi] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [isFatalCrash, setIsFatalCrash] = useState(false);
  const [userInputData, setUserInputData] = useState({});
  const { requestId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (requestId) {
      const api = new Api(requestId);
      setApi(api);
    }
  }, [requestId]);

  useEffect(() => {
    if (api) {
      setIsFatalCrash(false);
      getRequestData();
    }
  }, [api]);

  useEffect(() => {
    setLoadingError(api?.error);
  }, [api?.error]);

  useEffect(() => {
    if (clientData?.userInput) {
      setUserInputData(clientData.userInput);
    } else {
      setUserInputData(populateUserInputData(clientData));
    }
  }, [clientData]);

  const getRequestData = async () => {
    setDataLoading(true);
    const { data, error, statusCode } = await api.getRequest();
    setDataLoading(false);

    if (data) {
      setClientData(data);
    } else if (statusCode === 404) {
      navigate('/not-found', {
        state: { requestId: requestId, fromFull: window.location.href },
      });
    } else if (error) {
      setLoadingError(error);
      setIsFatalCrash(true);
    }
  };

  const submitUserInputData = async () => {
    setDataLoading(true);
    const preparedUserInputData = prepareUserInputData(userInputData);
    const { data, error } = await api.updateUserInput(preparedUserInputData);
    setDataLoading(false);

    if (data) {
      setClientData(data);
    } else if (error) {
      setLoadingError(error);
    }

    return !!data;
  };

  const generateDocumentFiles = async () => {
    setDataLoading(true);
    const { data, error } = await api.getDocumentFiles();
    setDataLoading(false);

    if (data) {
      setClientData(data);
    } else if (error) {
      setLoadingError(error);
    }

    return !!data;
  };

  const requestVerificationCode = async () => {
    setDataLoading(true);
    const { error } = await api.requestVerificationCode();
    setDataLoading(false);

    if (error) {
      setLoadingError(error);
    }
  };

  const signRequest = async (verificationCode, acceptedFileIds) => {
    setDataLoading(true);

    const { data, error } = await api.signRequest(
      verificationCode,
      acceptedFileIds,
    );

    setDataLoading(false);

    if (data) {
      setClientData(data);
    } else if (error) {
      setLoadingError(error);
    }

    return !!data;
  };

  const renderContent = () => {
    if (clientData?.signedAt) {
      return <SuccessBlock />;
    } else if (clientData?.interbankData) {
      return (
        <StepsMainContent scrollTop={scrollTop} scrollBottom={scrollBottom} />
      );
    } else {
      return <UntouchedRequestBlock requestId={requestId} />;
    }
  };

  return (
    <ApiContext.Provider
      value={{
        submitUserInputData,
        generateDocumentFiles,
        requestVerificationCode,
        signRequest,
        dataLoading,
      }}
    >
      <ClientDataContext.Provider value={{ clientData, setClientData }}>
        <UserInputDataContext.Provider
          value={{ userInputData, setUserInputData }}
        >
          <ErrorPopup
            errorText={loadingError}
            triggerClose={() => setLoadingError(null)}
            showContactButton
          ></ErrorPopup>
          {isFatalCrash ? <FatalError /> : ''}
          {clientData ? renderContent() : ''}
          {!clientData && dataLoading ? (
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                height: '70vh',
                justifyContent: 'center',
              }}
            >
              <LoadingIndicator />
            </div>
          ) : (
            ''
          )}
        </UserInputDataContext.Provider>
      </ClientDataContext.Provider>
    </ApiContext.Provider>
  );
};

export default RequestPage;
