import { stripPhoneFormatting } from './format';

export const populateUserInputData = (requestData) => {
  return {
    middleNameRu: requestData?.interbankData?.middleNameRu || null,
    sex: requestData?.interbankData?.sex || null,
    idDocumentExpireDate:
      requestData?.interbankData?.idDocumentExpireDate || null,

    isLivingAddressMatchesReg:
      requestData?.userInput?.isLivingAddressMatchesReg || true,

    livingAddressRegion:
      requestData?.interbankData?.livingAddressRegion || null,
    livingAddressDistrict:
      requestData?.interbankData?.livingAddressDistrict || null,
    livingAddressUnitaryArea:
      requestData?.interbankData?.livingAddressUnitaryArea || null,
    livingAddressLocalityType:
      requestData?.interbankData?.livingAddressLocalityType || null,
    livingAddressLocality:
      requestData?.interbankData?.livingAddressLocality || null,
    livingAddressStreetType:
      requestData?.interbankData?.livingAddressStreetType || null,
    livingAddressStreet:
      requestData?.interbankData?.livingAddressStreet || null,
    livingAddressHouse: requestData?.interbankData?.livingAddressHouse || null,
    livingAddressBuilding:
      requestData?.interbankData?.livingAddressBuilding || null,
    livingAddressApartment:
      requestData?.interbankData?.livingAddressApartment || null,
    livingAddressPostalCode:
      requestData?.interbankData?.livingAddressPostalCode || null,

    maritalStatus: requestData?.userInput?.maritalStatus || null,
    livingConditions: requestData?.userInput?.livingConditions || null,

    incomeMainType: requestData?.userInput?.incomeMainType || null,
    incomeMainAmount: requestData?.userInput?.incomeMainAmount || null,
    jobPlace: requestData?.userInput?.jobPlace || null,

    additionalPhoneNumber:
      requestData?.userInput?.additionalPhoneNumber || null,
  };
};

export const prepareUserInputData = (userInputData) => {
  const dataCopy = { ...userInputData };

  if (dataCopy.additionalPhoneNumber) {
    dataCopy.additionalPhoneNumber = stripPhoneFormatting(
      dataCopy.additionalPhoneNumber,
    );
  }

  return dataCopy;
};
